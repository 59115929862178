import React from "react";
import Particles from "react-particles-js";

export default function ParticlesDesign() {
  return (
    <Particles
      params={{
        particles: {
          number: {
            value: 50,
          },
          size: {
            value: 3,
          },
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
          },
        },
      }}
      className="absolute top-0 h-full w-full z-0"
    />
  );
}
