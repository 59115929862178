import React, { useState } from "react";
import ParticlesDesign from "./ParticlesDesign";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <ParticlesDesign />
      <section className="p-6 text-white rounded-lg shadow-md m-3 text-md">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>edgelearn.site!</b>
        </p>
        <p className="my-3">
          At edgelearn.site, we are dedicated to revolutionizing the learning
          experience by providing cutting-edge educational resources and tools.
          Our mission is to empower learners worldwide through accessible and
          innovative online education.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in [Year], edgelearn.site was created with the vision of
          bridging the gap between traditional education and modern technology.
          We believe that learning should be engaging, flexible, and tailored to
          individual needs.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team consists of educators, technologists, and content creators
          who are passionate about education. We work together to develop
          high-quality courses and materials that cater to a diverse range of
          learners.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Online Courses:</b> Comprehensive and interactive courses across
            various subjects.
          </li>
          <li className="my-2">
            <b>Learning Tools:</b> Innovative tools to enhance the learning
            experience.
          </li>
          <li className="my-2">
            <b>Support Community:</b> A vibrant community of learners and
            educators to support and inspire each other.
          </li>
        </ul>

        <p className="my-2">
          Thank you for choosing edgelearn.site as your learning partner. We are
          excited to support your educational journey!
        </p>
      </section>
    </>
  );
}
