import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <div className="relative flex cursor-pointer flex-col justify-end gap-3 overflow-hidden rounded-lg border-0 bg-gradient-to-r from-fuchsia-500 via-fuchsia-700 to-fuchsia-600 text-white hover:scale-x-95 hover:scale-y-105">
      <Link to={`/single?slug=${data.slug}`}>
        <img className="w-full rounded-md" src={data.logo} alt={data.title} />
        <p className="text-lg font-bold capitalize mb-4 text-center">
          {data.title}
        </p>
      </Link>
    </div>
  );
}
