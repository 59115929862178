import React from "react";

export default function Loader() {
  return (
    <section className="flex items-center justify-center p-8">
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-28 h-28 border-8 text-purple-400 text-4xl animate-spin border-sky-300 flex items-center justify-center border-t-amber-400 rounded-full"></div>
      </div>
    </section>
  );
}
